// src/stores/SpecialEventStore.ts

import { makeAutoObservable, runInAction } from 'mobx';
import API from '../api/client';
import { toast } from 'react-toastify';

interface SpecialEventFormData {
  name: string;
  email: string;
  phone: string;
  guests: number;
  message: string;
  desired_time: string | null; // e.g. '18:00'
}

class SpecialEventStore {
  formData: SpecialEventFormData = {
    name: '',
    email: '',
    phone: '',
    guests: 1,
    message: '',
    desired_time: null,
  };
  isLoading: boolean = false;
  submissionStatus: 'confirmed' | 'waitlisted' | null = null;
  waitlistMessage: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  // Setters
  setName(name: string) {
    this.formData.name = name;
  }

  setEmail(email: string) {
    this.formData.email = email;
  }

  setPhone(phone: string) {
    this.formData.phone = phone;
  }

  setGuests(guests: number) {
    this.formData.guests = guests;
  }

  setMessage(message: string) {
    this.formData.message = message;
  }

  setDesiredTime(timeStr: string) {
    this.formData.desired_time = timeStr;
  }

  async submitForm() {
    this.isLoading = true;
    try {
      // We'll send all fields including desired_time
      const payload = { ...this.formData,
                        event: 3  // Hardcode Valentine's Day at NP
                      };
      const response = await API.post('/api/special-event/', payload);

      runInAction(() => {
        this.isLoading = false;
      });

      if (response.status === 201) {
        toast.success('Your reservation has been confirmed!');
        runInAction(() => {
          this.submissionStatus = 'confirmed';
          this.resetForm();
        });
      } else if (response.status === 200) {
        // That means waitlisted
        runInAction(() => {
          this.submissionStatus = 'waitlisted';
          this.waitlistMessage = response.data.message;
          this.resetForm();
        });
        toast.info(response.data.message);
      }
      return true;
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
      });
      console.error('Error submitting reservation:', error);
      toast.error('There was an error submitting your request. Please try again.');
      return false;
    }
  }

  resetForm() {
    this.formData = {
      name: '',
      email: '',
      phone: '',
      guests: 1,
      message: '',
      desired_time: null,
    };
    this.submissionStatus = null;
    this.waitlistMessage = '';
  }
}

const stockYourCellarStore = new SpecialEventStore();
export default stockYourCellarStore;