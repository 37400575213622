import React from "react";
import Specials from "../../Menu/MenuItems/Specials";
import SpecialEventForm from "./SpecialForm";

interface SpecialEventFormProps {
  valentinesCategoryId: number;
}
const SpecialEvent: React.FC<SpecialEventFormProps> = ( { valentinesCategoryId } ) => {

  return (
    <>
      <SpecialEventForm />
      <div className="mt-12">
        <Specials categoryId={valentinesCategoryId} />
      </div>
      <SpecialEventForm />
    </>
    
  )
}

export default SpecialEvent;