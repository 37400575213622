// src/components/Special/InformationalNote.tsx

import React from 'react';

const InformationalNote: React.FC = () => (
  <div className="mt-4 text-xs text-gray-600 text-center">
    {/* <p>
      * "Stock Your Cellar" is a Wine Tasting Event with Complimentary Hors D'oeuvres at $55 per person.
      Proceeds will benefit Healthcare for Homeless Animals.
    </p>
    <p className="mt-2">
      The event is scheduled for November 9, 2024, from 12:00 PM to 3:00 PM at 1714-A Newbury Road, Newbury Park, CA.
      All reservations are subject to availability. Once the limit of 125 guests is reached, additional requests will be placed on a waitlist.
    </p> */}
  </div>
);

export default InformationalNote;