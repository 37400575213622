import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import specialEventStore from '../../../stores/SpecialEventStore';
import { toast } from 'react-toastify';
import InformationalNote from './InformationalNote';
import categoryStore from '../../../stores/CategoryStore';


const SpecialEventForm: React.FC = observer(() => {
  const [guestError, setGuestError] = useState('');

  useEffect(() => {
    categoryStore.fetchCategories();
  
    const urlParams = new URLSearchParams(window.location.search);
  
    const guestsParam = urlParams.get('guests');
    const timeParam = urlParams.get('time');
    const nameParam = urlParams.get('name');
    const emailParam = urlParams.get('email');
  
    // If guests is valid:
    if (guestsParam && !isNaN(Number(guestsParam))) {
      specialEventStore.setGuests(Number(guestsParam));
    }
  
    // If time is present, set desired_time
    if (timeParam) {
      specialEventStore.setDesiredTime(timeParam);
    }
  
    // If name is present and not empty, decode it
    if (nameParam) {
      const decodedName = decodeURIComponent(nameParam);
      if (decodedName.trim().length > 0) {
        specialEventStore.setName(decodedName);
      }
    }
  
    // If email is present and not empty
    if (emailParam) {
      const decodedEmail = decodeURIComponent(emailParam);
      if (decodedEmail.trim().length > 0) {
        specialEventStore.setEmail(decodedEmail);
      }
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, email, phone, guests, message, desired_time } = specialEventStore.formData;

    if (!name || !email || !phone || !guests || !message || !desired_time) {
      toast.error('Please fill out all required fields (including desired time).');
      return;
    }
    if (guests < 1 || guests > 10) {
      setGuestError('Please enter between 1 and 10 guests.');
      return;
    } else {
      setGuestError('');
    }

    await specialEventStore.submitForm();
  };

  const handleGuestsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value > 10) {
      setGuestError('Please enter no more than 10 guests.');
    } else {
      setGuestError('');
    }
    specialEventStore.setGuests(value);
  };

  return (
    <div className="relative text-white p-4 sm:p-6 lg:p-8 mt-[15vh]">
      <h2 className="text-3xl sm:text-5xl font-paperboard text-center mb-6 text-holdrens-gold">
        Valentine's Day Availability Inquiry
      </h2>
      <p className="max-w-2xl mx-auto text-center text-lg mb-8 text-balance">
      Kindly note that submitting this form does not guarantee a reservation; it simply allows us to confirm availability. 
      Should we be at capacity, your name will be added to our waitlist, and we will notify you by email if a table becomes available.
      </p>

      {specialEventStore.submissionStatus === 'confirmed' && (
        <div className="text-center bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-6">
          <strong className="font-bold">Inquiry Received!</strong>
          <p className="mt-2">
            Thank you for reaching out. We'll confirm details or waitlist you.
          </p>
        </div>
      )}

      {specialEventStore.submissionStatus === 'waitlisted' && (
        <div className="text-center bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded mb-6">
          <strong className="font-bold">Waitlist Notification</strong>
          <p className="mt-2">{specialEventStore.waitlistMessage || `You have been added to the waitlist.`}</p>
        </div>
      )}

      {specialEventStore.submissionStatus === null && (
        <form onSubmit={handleSubmit} className="space-y-6 max-w-xl mx-auto">
          {/* Hidden event code if needed */}
          <input type="hidden" name="event" value="Valentines_2025" readOnly />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4">
            {/* Name */}
            <div className="flex flex-col">
              <label className="text-sm font-paperboard mb-1 text-holdrens-gold">
                Name <span className="text-holdrens-red">*</span>
              </label>
              <input
                type="text"
                className="w-full px-4 py-2 bg-white border border-gray-300 focus:ring-2 focus:ring-holdrens-gold text-black"
                value={specialEventStore.formData.name}
                onChange={(e) => specialEventStore.setName(e.target.value)}
                required
              />
            </div>
            {/* Email */}
            <div className="flex flex-col">
              <label className="text-sm font-paperboard mb-1 text-holdrens-gold">
                Email <span className="text-holdrens-red">*</span>
              </label>
              <input
                type="email"
                className="w-full px-4 py-2 bg-white border border-gray-300 focus:ring-2 focus:ring-holdrens-gold text-black"
                value={specialEventStore.formData.email}
                onChange={(e) => specialEventStore.setEmail(e.target.value)}
                required
              />
            </div>
            {/* Phone */}
            <div className="flex flex-col">
              <label className="text-sm font-paperboard mb-1 text-holdrens-gold">
                Phone Number <span className="text-holdrens-red">*</span>
              </label>
              <input
                type="tel"
                className="w-full px-4 py-2 bg-white border border-gray-300 focus:ring-2 focus:ring-holdrens-gold text-black"
                value={specialEventStore.formData.phone}
                onChange={(e) => specialEventStore.setPhone(e.target.value)}
                required
              />
            </div>
            {/* Guests */}
            <div className="flex flex-col">
              <label className="text-sm font-paperboard mb-1 text-holdrens-gold">
                Number of Guests <span className="text-holdrens-red">*</span>
              </label>
              <input
                type="number"
                min="1"
                max="10"
                className="w-full px-4 py-2 bg-white border border-gray-300 focus:ring-2 focus:ring-holdrens-gold text-black"
                value={specialEventStore.formData.guests || ''}
                onChange={handleGuestsChange}
                required
              />
              {guestError && <p className="text-xs text-holdrens-red mt-1">{guestError}</p>}
            </div>
            {/* Desired Time */}
            <div className="flex flex-col col-span-1 md:col-span-2">
              <label className="text-sm font-paperboard mb-1 text-holdrens-gold">
                Preferred Time <span className="text-holdrens-red">*</span>
              </label>
              <select
                className="w-full px-4 py-2 bg-white border border-gray-300 focus:ring-2 focus:ring-holdrens-gold text-black"
                value={specialEventStore.formData.desired_time || ''}
                onChange={(e) => specialEventStore.setDesiredTime(e.target.value)}
                required
              >
                <option value="">Select a time</option>
                <option value="16:00">4:00 PM</option>
                <option value="16:30">4:30 PM</option>
                <option value="17:00">5:00 PM</option>
                <option value="17:30">5:30 PM</option>
                <option value="18:00">6:00 PM</option>
                <option value="18:30">6:30 PM</option>
                <option value="19:00">7:00 PM</option>
                <option value="19:30">7:30 PM</option>
                <option value="20:00">8:00 PM</option>
                <option value="20:30">8:30 PM</option>
              </select>
            </div>
            {/* Message */}
            <div className="flex flex-col col-span-1 md:col-span-2">
              <label className="text-sm font-paperboard mb-1 text-holdrens-gold">
                Additional Requests <span className="text-holdrens-red">*</span>
              </label>
              <textarea
                rows={3}
                className="w-full px-4 py-2 bg-white border border-gray-300 focus:ring-2 focus:ring-holdrens-gold text-black"
                value={specialEventStore.formData.message}
                onChange={(e) => specialEventStore.setMessage(e.target.value)}
                required
              />
            </div>
          </div>

          <InformationalNote />

          <div className="text-center mt-6">
            <button
              type="submit"
              className="px-8 py-3 bg-holdrens-red text-white font-paperboard font-semibold hover:bg-holdrens-gold transition-colors"
              disabled={specialEventStore.isLoading}
            >
              {specialEventStore.isLoading ? 'Submitting...' : 'Check Availability'}
            </button>
          </div>
        </form>
      )}
    </div>
  );
});

export default SpecialEventForm;