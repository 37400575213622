import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import menuItemStore from "../../../stores/MenuItemStore";
import categoryStore from "../../../stores/CategoryStore";
import { MenuItem as MenuItemType, Category } from "../../../types";
import { motion } from "framer-motion";
import HeritageInfinity from "../../../assets/images/Heritage-Infinity.svg";

interface SpecialsProps {
  categoryId: number;
}

interface GroupedSubcategory {
  id: number;
  name: string;
  order: number;
  items: MenuItemType[];
}

/**
 * Returns "text-center" if isPricePerPerson is true,
 * otherwise "text-left".
 */
function getAlignmentClass(isPricePerPerson: boolean) {
  return isPricePerPerson ? "text-center" : "text-left";
}

const Specials: React.FC<SpecialsProps> = observer(({ categoryId }) => {
  useEffect(() => {
    menuItemStore.fetchMenuItemsByCategory(categoryId);
  }, [categoryId]);

  // Find the Category
  const category: Category | undefined = categoryStore.categories.find(
    (cat) => cat.id === categoryId
  );

  // Loading / error handling
  if (menuItemStore.loading[categoryId]) {
    return (
      <div className="flex justify-center items-center space-x-2 text-lg sm:text-xl md:text-2xl">
        <svg
          className="animate-spin h-8 w-8 text-gray-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8v8H4z"
          ></path>
        </svg>
        <span>Loading sections...</span>
      </div>
    );
  }

  if (menuItemStore.error[categoryId]) {
    return (
      <div className="text-center text-red-500 text-lg sm:text-xl md:text-2xl">
        Failed to load menu items. Please try again later.
      </div>
    );
  }

  // Get menu items for this category
  const menuItems: MenuItemType[] = menuItemStore.getMenuItemsByCategory(categoryId);

  // Step 1: Build an object keyed by subcategory ID
  const groupedSubcategories: Record<number, GroupedSubcategory> = {};

  for (const item of menuItems) {
    const subcat = item.subcategory;
    const subcatId = subcat?.id ?? -1;
    const subcatName = subcat?.name ?? "Entrees";
    const subcatOrder = subcat?.order ?? 9999;

    if (!groupedSubcategories[subcatId]) {
      groupedSubcategories[subcatId] = {
        id: subcatId,
        name: subcatName,
        order: subcatOrder,
        items: [],
      };
    }
    groupedSubcategories[subcatId].items.push(item);
  }

  // Step 2: Convert to array & sort by subcat.order
  const subcategoryArray = Object.values(groupedSubcategories);
  subcategoryArray.sort((a, b) => a.order - b.order);

  // Helper: format item-level prices (used only if NOT is_price_per_person)
  function formatPrice(price?: string | null): string {
    // If it's null or empty, fallback to "MP"
    if (!price) return "MP";
    const numericPrice = parseFloat(price);
    if (isNaN(numericPrice)) {
      return "MP";
    }
    // Then format as $xx.xx
    return numericPrice % 1 !== 0
      ? `$${numericPrice.toFixed(2)}`
      : `$${numericPrice.toFixed(0)}`;
  }

  // Helper: format the category-level per-person price
  function formatPerPersonPrice(price?: string | null) {
    if (!price) {
      return "Call for more pricing info!";
    }
    const numericPrice = parseFloat(price);
    if (isNaN(numericPrice)) {
      return "Call for more pricing info!";
    }
    return `$${numericPrice.toFixed(2)} per person`;
  }

  // Extract the relevant category-level flags
  const isPricePerPerson = category?.is_price_per_person || false;
  const perPersonPrice = category?.per_person_price || null;

  // We'll use this alignment in our subcategory titles and item text
  const alignmentClass = getAlignmentClass(isPricePerPerson);

  return (
    <div className="flex flex-col items-center px-4 mb-8 sm:px-8">
      {/* Container for Menu Items and Notes */}
      <div className="w-full max-w-6xl flex flex-col md:flex-row md:gap-8">
        {/* Right Half: Notes */}
        <div className="w-full md:w-1/3 flex flex-col items-center order-first md:order-none mb-12 md:mb-0">
          {/* Top HeritageInfinity SVG */}
          <img
            src={HeritageInfinity}
            alt=""
            aria-hidden="true"
            className="w-16 h-16 sm:w-24 sm:h-24 md:w-32 md:h-32 mb-4"
          />

          {/* Display category notes */}
          {category?.notes?.length ? (
            <div className={`flex flex-col items-center ${alignmentClass}`}>
              {category.notes.map((note, index) => (
                <p key={index} className="text-lg sm:text-xl font-lora mb-4">
                  {note}
                </p>
              ))}
            </div>
          ) : null}

          {/* If the category has a single price per person, display it here */}
          {isPricePerPerson && (
            <p className={`text-xl sm:text-2xl font-paperboard text-holdrens-red ${alignmentClass}`}>
              {formatPerPersonPrice(perPersonPrice)}
            </p>
          )}

          {/* Bottom HeritageInfinity SVG */}
          <img
            src={HeritageInfinity}
            alt=""
            aria-hidden="true"
            className="w-16 h-16 sm:w-24 sm:h-24 md:w-32 md:h-32 mt-4"
          />
        </div>

        {/* Left Half: Subcategories & Items */}
        <div className="w-full md:w-2/3 flex flex-col items-start">
          {subcategoryArray.map((subCat) => {
            const items = subCat.items;
            if (!items || items.length === 0) return null; // skip empty subcat

            return (
              <div key={subCat.id} className="mb-12 w-full">
                {/* Subcategory Name */}
                <h3 className={`text-2xl sm:text-3xl font-paperboard mb-6 ${alignmentClass}`}>
                  {subCat.name}
                </h3>

                {items.map((item) => (
                  <motion.div
                    key={item.id}
                    className="mb-12 flex flex-col w-full"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <div className={`w-full ${alignmentClass} flex flex-col sm:flex-row sm:items-center sm:justify-center`}>
                      {/* Item Title */}
                      <h4 className="text-lg sm:text-2xl font-paperboard font-bold uppercase whitespace-normal overflow-wrap break-words flex-shrink-0 min-w-[100px]">
                        {item.title}
                      </h4>

                      {/* If NOT per-person pricing, show item-level price with a decorative line */}
                      {!isPricePerPerson && (
                        <div className="sm:flex sm:items-center sm:flex-grow sm:ml-4 sm:mr-4">
                          <div className="border-t-2 border-holdrens-red mt-2 sm:mt-0 sm:flex-grow" />
                          <div className="text-lg sm:text-2xl font-paperboard mt-2 sm:mt-0 sm:ml-4">
                            {formatPrice(item.price)}
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Description */}
                    {item.description && (
                      <div className="mt-4 w-full">
                        <p className={`font-lora text-base sm:text-lg leading-tight ${alignmentClass}`}>
                          {item.description}
                        </p>
                      </div>
                    )}
                  </motion.div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default Specials;