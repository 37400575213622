// src/components/MenuFunctions/CategorySection.tsx
import React from 'react';
import PdfStore from '../../../stores/PdfStore';
import MenuItem from '../MenuItems';
import CombinationAndSidesMenuItem from '../MenuItems/CombinationAndSidesMenuItem';
import { Category } from '../../../types';
import Specials from '../MenuItems/Specials';

interface CategorySectionProps {
  category: Category;
  isVisible: boolean;
}

const CategorySection: React.FC<CategorySectionProps> = ({ category, isVisible }) => {
  const pdfUpload = PdfStore.pdfs.find((pdf) => pdf.category === category.id);
  return (
    <div className="mb-12 sm:mb-16 md:mb-24" data-category-id={category.id}>
      {pdfUpload ? (
        <div className="text-center">
          <a
            href={pdfUpload.file}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block px-4 py-2 sm:px-6 sm:py-3 text-xl sm:text-2xl md:text-3xl font-paperboard text-white bg-holdrens-red hover:bg-holdrens-gold rounded-full shadow-md transition duration-300"
          >
            View {category.name}
          </a>
        </div>
      ) : (
        isVisible && (
          <>
            { category.name === 'Combinations & Sides' ? (
              <CombinationAndSidesMenuItem categoryId={category.id} />
            ) : category.is_special? ( // New condition
              <Specials categoryId={category.id} />
            ) : (
              <MenuItem categoryId={category.id} />
            )}
          </>
        )
      )}
    </div>
  );
};

export default CategorySection;